<template>
  <div id="myaccount">
    <header class="inner-head">
      <div class="flex justify-between">
        <h2 class="inner-head-title">My Account</h2>

        <ul class="pt-1 flex">
          <li>
            <button
              @click="logout()"
              type="button"
              class="publish-button bg-action-red hover:opacity-75 mr-4"
            >
              Logout
            </button>
          </li>
          <li>
            <button
              @click="submitForm()"
              type="button"
              class="publish-button hover:opacity-75"
            >
              Publish Changes
            </button>
          </li>
        </ul>
      </div>
    </header>

    <div v-if="errored" class="m-5 px-6">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>

    <div id="content-area" v-else>
      <div
        style="width: 100%; text-align: center"
        class="spin-holder mt-4"
        v-if="loading"
      >
        <i class="spinner fa fa-spinner fa-spin"></i>
        <p>Loading Account details</p>
      </div>

      <form
        v-on:submit.prevent="submitSchool"
        id="schoolForm"
        ref="form"
        v-else
      >
        <section class="bg-white w-11/12 mx-auto rounded-lg p-8 mt-6">
          <savenotice :title="'Account'" ref="saveNoticeTop"></savenotice>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-11/12 px-3">
              <label class="form-label" for="grid-title"> School Name * </label>
              <input
                v-model="object.name"
                class="
                  form-input
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="grid-title"
                type="text"
                placeholder="Enter school name"
                required=""
              />
              <p class="text-red-300 text-xs italic">
                Please fill out this field.
              </p>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-1/4 px-3">
              <label class="form-label" for="grid-postcode"> Postcode * </label>
              <input
                v-model="object.postcode"
                class="
                  form-input
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="grid-postcode"
                type="text"
                placeholder="Enter postcode"
                required=""
              />
              <p class="text-red-300 text-xs italic">
                Please fill out this field.
              </p>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-11/12 px-3">
              <label class="form-label" for="grid-address"> Address </label>
              <input
                v-model="object.address"
                class="
                  form-input
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="grid-address"
                type="text"
                placeholder="Enter address"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-5/12 px-3 mb-6 md:mb-0">
              <label class="form-label" for="grid-city"> City </label>
              <input
                v-model="object.city"
                class="
                  form-input
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="grid-city"
                type="text"
                placeholder="Enter city"
              />
            </div>
            <div class="w-full md:w-6/12 px-3">
              <label class="form-label" for="grid-county"> County </label>
              <input
                v-model="object.county"
                class="
                  form-input
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="grid-county"
                type="text"
                placeholder="Enter county"
              />
            </div>
          </div>

          <div v-if="hasAdminAccess" class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full md:w-5/12 px-3">
              <label class="form-label" for="grid-postcode">
                PE Lead Contact</label
              >
              <input
                v-model="leadContact"
                class="
                  form-input
                  focus:outline-none focus:bg-white focus:border-gray-500
                "
                id="grid-postcode"
                type="email"
                placeholder="Enter PE Lead contact email"
              />
            </div>
          </div>

          <div class="pb-4">
            <savenotice
              :title="'Account'"
              ref="saveNoticeBottom"
              class="left"
            ></savenotice>
          </div>
        </section>
      </form>
    </div>


    <div class="w-full text-center mt-12 opacity-25">
        &copy; PE Passport | v.{{ this.$version }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BaseService } from "@/services/school.service";
const savenotice = () => import("@/components/SaveNotice.vue");

export default {
  name: "myaccount",
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
    isDisabled() {
      if (
        (this.object.name == undefined || this.object.name.length > 0) &&
        (this.object.postcode == undefined || this.object.postcode.length > 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
    showForSchool() {
      return this.currentUser.isSchool;
    },
    showForCompany() {
      return this.currentUser.isSchool == false;
    },
    hasAdminAccess() {
      return this.currentUser.isAdmin;
    },
  },
  data() {
    return {
      object: {},
      loading: true,
      errored: false,
      leadContact: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    fetchData() {
      BaseService.get("owner/school")
        .then((response) => this.parseSchool(response.data))
        .catch((err) => {
          console.log("Events " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    parseSchool(data) {
      this.object = data;
      this.leadContact =
        this.object.pe_contact == undefined
          ? ""
          : this.object.pe_contact.String;
    },
    submitForm() {
      if (this.$refs.form.requestSubmit === undefined) {
        this.processSchool();
      } else {
        this.$refs.form.requestSubmit();
      }
    },
    submitSchool() {
      if (this.object.name == "" || this.object.postcode == "") {
        this.alertDisplay();
      } else {
        this.processSchool();
      }
    },
    alertDisplay() {
      this.$swal({
        title: "Empty Fields",
        text: "You have empty fields that are required",
        type: "warning",
        showCancelButton: false,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      });
    },
    processSchool() {
      this.object.pe_contact = {
        String: this.leadContact,
        Valid: true,
      };

      BaseService.update("owner/school", this.object)
        .then((response) => this.completion(true))
        .catch((err) => {
          this.completion(false);
          // console.log("Account update error " + err);
        });
    },
    completion(val) {
      if (val == true) {
        this.$refs.saveNoticeTop.saved();
        this.$refs.saveNoticeBottom.saved();
        window.scrollTo(0, 0);
      } else {
        this.$refs.saveNoticeTop.failed();
        this.$refs.saveNoticeBottom.failed();
      }
    },
  },
  components: {
    savenotice,
  },
};
</script>
<style lang="css" scoped>
.form-label {
  @apply block uppercase tracking-wide text-gray-700 text-xs font-bold  mb-2;
}

.form-input {
  @apply appearance-none block w-full  bg-gray-100  text-gray-700 border border-gray-200  rounded py-3 px-4 mb-3 leading-tight;
}
</style>
